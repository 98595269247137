import * as React from "react";
import Layout from '../components/layout';
import Container from '../components/container';
import {StaticImage} from "gatsby-plugin-image";

class RegulaminPage extends React.Component {
    render () {
        return (
            <Layout>
                <section className="relative pt-[4.8125rem] md:pt-[6.8125rem]">
                    <StaticImage src="../images/bg.jpg" alt="" className="!absolute !inset-0 !z-[-1]"  />
                    <Container>
                        <div className="">
                            <h1 className="text-white text-3xl font-semibold py-8 md:text-6xl md:py-12 lg:text-7xl lg:py-16">Regulamin</h1>
                        </div>
                    </Container>
                </section>

                <section>
                    <Container>
                        <div className="prose py-12 md:py-16 max-w-none">
                            <h2>I. Postanowienia ogólne</h2>

                            <h3>§ 1. Przedmiot regulaminu</h3>

                            <ol>
                                <li>Niniejszy dokument (dalej <strong>„Regulamin”</strong>) określa zasady uczestnictwa w programie „#ChangeShapers” (dalej <strong>„Program”</strong>), organizowanego przez Higher Sp. z o.o. (dalej <strong>„Organizator”</strong>) we współpracy z partnerem głównym programu Accenture Sp. z o.o. i Accenture Services Sp. z o.o. (dalej <strong>„Partner”</strong>).</li>
                            </ol>

                            <h3>§ 2. Definicje</h3>

                            <p><strong>Ambasador</strong> – osoba, która zgłosiła chęć udziału w Programie, skorzystanie ze szkoleń i konsultacji oraz zorganizowanie spotkania z Uczestnikami.</p>
                            <p><strong>Formularz dla Uczestników</strong> – formularz znajdujący się na stronie https://changeshapers.pl wypełniany przez Uczestników w celu wzięcia udziału w spotkaniu organizowanym w ramach Programu przez Ambasadora.</p>
                            <p><strong>Formularz rejestracyjny</strong> – formularz znajdujący się na stronie https://changeshapers.pl wypełniany przez Ambasadora w trakcie Rejestracji.</p>
                            <p><strong>Organizator</strong> – Higher spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, przy ul. Lwowskiej 5/15, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m. st. Warszawy, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000578945; posiadająca numer identyfikacji podatkowej NIP: 525-263-13-00, nr REGON: 362659709. Zmiana którejkolwiek z ww. danych Usługodawcy, w tym podmiotu prawnego będącego Usługodawcą, nie stanowi zmiany Regulaminu.</p>
                            <p><strong>Partner</strong> – Accenture spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, przy ul. Siennej 39, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m. st. Warszawy, XIX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000019271; posiadająca numer identyfikacji podatkowej NIP: 526-001-59-00, nr REGON: 01036092000000 oraz Accenture Services spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, przy ul. Siennej 39, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m. st. Warszawy, XIX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000043465; posiadająca numer identyfikacji podatkowej NIP: 525-221-91-43, nr REGON: 01736813200000.</p>
                            <p><strong>Program</strong> – #ChangeShapers to program rozwojowy obejmujący spotkania oraz pakiet szkoleń w zakresie umiejętności z obszaru autoprezentacji, komunikacji oraz kompetencji przyszłości. Szkolenia organizowane są pod patronatem firmy Accenture Polska. Kluczowym elementem programu jest połączenie Uczestników z Uczniami szkół średnich w ramach zorganizowanych spotkań, podczas których Uczestnicy podzielą się wiedzą i doświadczeniem dot. studiowania lub działalności w organizacjach studenckich z Uczniami wybranych szkół średnich.</p>
                            <p><strong>Regulamin</strong> – niniejszy dokument.</p>
                            <p><strong>Rejestracja</strong> – czynność konieczna do wzięcia udziału w Programie i skorzystania z Usługi przez Ambasadora.</p>
                            <p><strong>RODO</strong> – rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).</p>
                            <p><strong>Uczestnik</strong> – osoba ze szkoły średniej, która zgłosiła chęć udziału w spotkaniu zorganizowanym przez Ambasadora.</p>
                            <p><strong>Ustawa</strong> – ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz.U.2020.344 t.j.).</p>
                            <p><strong>Usługa</strong> – usługa oferowana przez Partnera na rzecz Ambasadora polegająca na zapewnieniu szkoleń i konsultacji.</p>

                            <h3>§ 3. Podstawy prawne</h3>

                            <ol>
                                <li>Niniejszy Regulamin został sporządzony w oparciu o przepisy prawne obowiązujące na terytorium Rzeczypospolitej Polskiej, w szczególności na podstawie Ustawy.</li>
                                <li>Usługa świadczona jest na podstawie Regulaminu.</li>
                                <li>Usługodawca nieodpłatnie udostępnia Ambasadorowi Regulamin przed zgłoszeniem do Programu, a także – na jego żądanie – w taki sposób, który umożliwia pozyskanie, odtwarzanie i utrwalanie treści Regulaminu za pomocą Systemu teleinformatycznego, którym posługuje się Ambasador.</li>
                                <li>
                                    W celu uniknięcia wszelkich wątpliwości Organizator oświadcza, że:
                                    <ol className="list-[lower-alpha]">
                                        <li>Organizator ani Partner nie zawiera umów z konsumentami w rozumieniu art. 221 kodeksu cywilnego;</li>
                                        <li>konsumenci nie korzystają odpłatnie z usług;</li>
                                        <li>w związku z czym do niniejszego Regulaminu nie znajdują zastosowania przepisy prawa polskiego chroniące interesy konsumentów, a w szczególności przepisy kodeksu cywilnego o postanowieniach niedozwolonych (art. 3851 i następne kodeksu cywilnego) ani ustawa o prawach konsumenta.</li>
                                    </ol>
                                </li>
                                <li>W sprawach nieuregulowanych w Regulaminie zastosowanie mają przepisy o świadczeniu usług drogą elektroniczną, o ochronie danych osobowych, kodeksu cywilnego i inne przepisy prawne bezwzględnie obowiązujące na terytorium Rzeczypospolitej Polskiej.</li>
                            </ol>

                            <h2>II. Udział w Programie</h2>

                            <h3>§ 4. Wymagania względem Ambasadorów</h3>

                            <ol>
                                <li>W celu skorzystania z Usługi niezbędna jest Rejestracja przez Formularz rejestracyjny.</li>
                                <li>
                                    Aby Rejestracja została uznana za skuteczną Ambasador musi przesłać swoje:
                                    <ol className="list-[lower-alpha]">
                                        <li>Imię;</li>
                                        <li>Nazwisko;</li>
                                        <li>Numer telefonu;</li>
                                        <li>Adres e-mail;</li>
                                        <li>Nazwę uczelni, na której studiuje;</li>
                                        <li>Nazwę swojego aktualnego kierunku studiów;</li>
                                        <li>Nazwę organizacji studenckiej, której jest członkiem;</li>
                                        <li>Zdjęcie.</li>
                                    </ol>
                                </li>
                                <li>Dane z punktu 4.2, lit. a. - g. mogą zostać przekazane do Partnera do celów realizacji Programu oraz prowadzonych przez Partnera rekrutacji.</li>
                                <li>W Programie wziąć mogą udział jedynie osoby aktualnie posiadające status studenta oraz które jednocześnie są członkami organizacji studenckiej, koła naukowego lub samorządu studenckiego.</li>
                                <li>Do udziału w Programie zakwalifikowane zostaną wybrane przez Partnera osoby. Poinformowane zostaną o tym za pomocą podanego numeru telefonu komórkowego lub adresu e-mail.</li>
                                <li>Po zakwalifikowaniu Ambasadorzy zobowiązani są do przesłania swojego zdjęcia w formacie .jpg w wysokiej rozdzielczości (przynajmniej 1000 x 1000 pikseli).</li>
                                <li>
                                    Wyrażenie zgody na udział w Programie oznacza wyrażenie zgody na nieodpłatne wykorzystanie wizerunku (wraz z możliwością jego opisania imieniem i nazwiskiem) w celach:
                                    <ol className="list-[lower-alpha]">
                                        <li>Publikacji na stronie internetowej Programu;</li>
                                        <li>Publikacji w mediach społecznościowych Partnera;</li>
                                        <li>Publikacji w materiałach wewnętrznych Partnera (np. na tablicach informacyjnych).</li>
                                    </ol>
                                </li>
                                <li>Przeniesienie zezwolenia, o którym mowa w punkcie 4.6 na osobę trzecią wymaga uprzedniej zgody Ambasadora na taką czynność.</li>
                                <li>Dane osobowe Ambasadora (imię, nazwisko, wizerunek) będą wykorzystywane zgodnie z treścią poniższego obowiązku informacyjnego:</li>
                            </ol>

                            <p>Administratorem danych osobowych (imię, nazwisko, wizerunek) w rozumieniu ogólnego rozporządzenia o ochronie danych (RODO) jest Organizator. Dane są wykorzystywane w celach wskazanych w punkcie 4.6, na podstawie dobrowolnie udzielonej zgody (art. 6 ust. 1 lit. a RODO, art. 81 ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych). Zgoda ta może być odwołana w każdym czasie (odwołanie nie ma wpływu na zgodność z prawem wykorzystania danych w okresie, gdy zgoda obowiązywała). Dane mogą być przekazywane podmiotom współpracującym z Organizatorem (np. firmom obsługującym strony internetowe Organizatora). Osobie, której dane dotyczą, przysługuje prawo dostępu do danych osobowych, sprostowania, usunięcia, ograniczenia przetwarzania lub złożenia skargi do organu nadzorczego – na zasadach określonych w RODO. Kontakt w sprawach ochrony danych osobowych: rodo@jamano.pl.</p>
                            
                            <h3>§ 5. Przebieg Programu</h3>

                            <ol>
                                <li>Po dokonaniu kwalifikacji i potwierdzeniu chęci udziału w Programie przez Ambasadora dochodzi do powstania pomiędzy Ambasadorem, a Partnerem stosunku prawnego umożliwiającego Ambasadorowi nieodpłatny dostęp do szkoleń i konsultacji zapewnianych przez Partnera.</li>
                                <li>Udział w Programie nie wymaga żadnych nakładów finansowych ze strony Ambasadora. Wszelkie koszty powstałe w ramach jego realizacji, po uprzednim zatwierdzeniu z Partnerem, zostaną pokryte przez Partnera.</li>
                                <li>Program rozpocznie się serią szkoleń dla Ambasadorów, w których udział Ambasadorów jest obligatoryjny.</li>
                                <li>Prowadzone przez pracowników Partnera konsultacje mają charakter fakultatywny.</li>
                                <li>W ramach Programu Ambasadorzy zobowiązani są do zorganizowania i poprowadzenia minimum jednego (1) spotkania z Uczestnikami w okresie maj-czerwiec 2022 w wybranej i ustalonej z Partnerem formie (offline lub online), w trakcie którego podzielą się swoją wiedzą i doświadczeniami dotyczącymi studiowania lub działalności w organizacjach studenckich z Uczniami wybranych szkół średnich. Partner nie pokrywa ewentualnych kosztów związanych z dojazdem do miejsca przeprowadzania spotkania.</li>
                                <li>
                                    Organizacja spotkania polega w szczególności na:
                                    <ol className="list-[lower-alpha]">
                                        <li>Skontaktowaniu się z dowolną szkołą średnią (np. której jest się absolwentem).</li>
                                        <li>Stworzeniu prezentacji.</li>
                                        <li>W przypadku organizacji spotkania offline - zarezerwowania i przygotowania sali, w której odbędzie się spotkanie.</li>
                                        <li>W przypadku organizacji spotkania online - stworzenia wideokonferencji (np. za pomocą Zoom czy Google Meet), zapewniania sobie stabilnego łącza internetowego i sprzętu umożliwiającego przeprowadzenie spotkania.</li>
                                        <li>Promocji spotkania wśród uczniów szkoły średniej.</li>
                                        <li>Zapewnieniu informacji o spotkaniu Uczestnikom.</li>
                                    </ol>
                                </li>
                                <li>W ramach zadań związanych z organizacją spotkania dostępne są konsultacje prowadzone przez pracowników Partnera, które pomogą w ich skutecznej realizacji.</li>
                                <li>Program kończy się w czerwcu 2022 spotkaniem zorganizowanym przez Partnera.</li>
                            </ol>

                            <h3>§ 6. Przetwarzanie danych osobowych Ambasadorów</h3>

                            <ol>
                                <li>Administratorem danych osobowych  Ambasadorów w rozumieniu art. 26 RODO jest Organizator. Organizator jest odpowiedzialny za realizację obowiązków wynikających z RODO, w szczególności w odniesieniu do wykonywania przez osobę, której dane dotyczą, przysługujących jej praw, oraz ich obowiązków w odniesieniu do podawania informacji, o których mowa w art. 13 i 14 RODO (obowiązek informacyjny).</li>
                                <li>
                                    Zakres danych osobowych
                                    <ol className="list-[lower-alpha]">
                                        <li>
                                            Dane osobowe gromadzone o Ambasadorach:
                                            <ul>
                                                <li>Imię;</li>
                                                <li>Nazwisko;</li>
                                                <li>Numer telefonu;</li>
                                                <li>Adres e-mail;</li>
                                                <li>Nazwa uczelni;</li>
                                                <li>Nazwa kierunku studiów;</li>
                                                <li>Nazwa organizacji studenckiej.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Inne informacje gromadzone:
                                            <ul>
                                                <li>Data i godzina przesłania Formularza rejestracyjnego;</li>
                                                <li>Dane Google Analytics (dane statystyczne, nie dotyczą konkretnych osób).</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <p>Cel i podstawa prawna dla wykorzystania danych osobowych</p>

                                    <p>Dane Ambasadorów gromadzone są w celu:</p>

                                    <ol className="list-[lower-alpha]">
                                        <li>organizacji Programu i kontaktu z Ambasadorami (art. 6 ust. 1 lit. b RODO);</li>
                                        <li>realizacji praw i obowiązków Ambasadora wskazanych w niniejszym Regulaminie (art. 6 ust. 1 lit. b RODO);</li>
                                        <li>dochodzenia lub obrony przed ewentualnymi roszczeniami, związanymi z korzystaniem z Programu lub też w związku z potrzebą wykazania określonych faktów, mających w tym zakresie istotne znaczenie dla współadministratorów (rt.. 6 ust. 1 lit. f RODO); terminy dochodzenia roszczeń szczegółowo określa Kodeks cywilny.</li>
                                    </ol>

                                    <p>Dane nie będą wykorzystywane dla podejmowania decyzji opartych wyłącznie na zautomatyzowanym przetwarzaniu danych osobowych, w tym profilowania w rozumieniu art. 22 RODO.</p>
                                </li>
                                <li>
                                    <p>Okres wykorzystania danych osobowych</p>

                                    <p>Dane Ambasadorów będą wykorzystywane przez okres:</p>

                                    <ol className="list-[lower-alpha]">
                                        <li>realizacji Programu oraz</li>
                                        <li>dochodzenia ewentualnych roszczeń związanych z udziałem w Programie.</li>
                                    </ol>
                                </li>
                                <li>
                                    <p>Udostępnianie danych osobowych</p>

                                    <p>Dane Ambasadorów mogą być udostępniane osobom fizycznym upoważnionym przez Organizatora, a także współpracującym z Organizatorem zewnętrznym firmom informatycznym lub księgowym w celu świadczenia usług wynikających z Regulaminu i realizacji obowiązków Organizatora wynikających z przepisów prawa. W przypadku, gdy przekazywanie danych tym podmiotom oznaczać będzie ich przekazywanie poza Europejski Obszar Gospodarczy, Usługodawca zrealizuje obowiązki wynikające z rozdziału V RODO.</p>
                                </li>
                                <li>
                                    <p>Prawa osób, których dane dotyczą</p>

                                    <p>Osoba, której dane osobowe są wykorzystywane, może złożyć do Organizatora wniosek o:</p>

                                    <ol className="list-[lower-alpha]">
                                        <li>dostęp do danych osobowych (informację o przetwarzanych danych osobowych oraz kopię danych),</li>
                                        <li>sprostowanie danych, gdy są one nieprawidłowe,</li>
                                        <li>przeniesienie danych,</li>
                                        <li>usunięcie lub ograniczenie przetwarzania danych osobowych,</li>
                                    </ol>

                                    <p>na zasadach określonych w RODO.</p>

                                    <p>Osoba, której dane dotyczą, ma także prawo wnieść sprzeciw wobec wykorzystania danych osobowych (gdy podstawą dla ich wykorzystania jest art. 6 ust. 1 lit. f RODO). Osoba, której dane dotyczą, ma także prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych (ul. Stawki 2, 00-193 Warszawa) w razie stwierdzenia, że dane osobowe są wykorzystywane niezgodnie z przepisami prawa.</p>
                                </li>
                                <li>W sprawach dotyczących ochrony danych osobowych można kontaktować się z Inspektorem Ochrony Danych Organizatora: rodo@jamano.pl.</li>
                            </ol>

                            <h3>§ 7. Zmiany Regulaminu</h3>

                            <ol>
                                <li>Organizator zastrzega sobie prawo do jednostronnej zmiany postanowień Regulaminu w przypadku zmiany obowiązujących przepisów, zmiany zasad świadczenia usług lub wprowadzenia nowych usług. O zmianie Regulaminu Organizator zawiadomi wysyłając wiadomość e-mail na adres poczty elektronicznej podany podczas Rejestracji. Poinformowanie o zmianie Regulaminu, nastąpi nie później niż np. w terminie 14 dni kalendarzowych przed wejściem w życie nowego Regulaminu.</li>
                                <li>Wszelkie zmiany niniejszego Regulaminu wchodzą w życie z chwilą opublikowania ich na stronie internetowej https://changeshapers.pl/regulamin.</li>
                                <li>W razie niewyrażenia zgody na zmiany w Regulaminie Ambasador ma prawo wypowiedzieć umowę o świadczenie Usługi z zachowaniem terminu 1-miesięcznego okresu wypowiedzenia ze skutkiem na koniec miesiąca kalendarzowego. Brak wypowiedzenia umowy w ciągu 14 dni od daty otrzymania przez Ambasadora informacji o zmianie Regulaminu uważa się za akceptację zmian w Regulaminie.</li>
                            </ol>

                            <h3>§ 8. Postanowienia końcowe</h3>

                            <ol>
                                <li>Regulamin wchodzi w życie z dniem 12 kwietnia 2022 roku.</li>
                                <li>Regulamin dostępny jest do wglądu w formie elektronicznej na stronie internetowej https://changeshapers.pl/regulamin w formacie umożliwiającym pobranie go na dysk twardy, odczyt oraz wydruk.</li>
                                <li>Postanowienia Regulaminu stanowią integralną część umowy zawartej pomiędzy Ambasadorem i Partnerem  są wiążące, o ile postanowienia umowy nie stanowią inaczej.</li>
                                <li>Skrócony opis zasad Regulaminu może znajdować się na innych niż określonych w punkcie 8.2 stronach internetowych Organizatora lub Partnera, a także na materiałach reklamowych lub promocyjnych. Wszystkie treści zawarte w materiałach reklamowo-promocyjnych mają jedynie charakter informacyjny.</li>
                                <li>Ewentualne spory między Partnerem, a Ambasadorem powstałe na gruncie Regulaminu lub umowy będą rozstrzygane w drodze negocjacji, a jeśli na tej drodze nie dojdzie do porozumienia, właściwym do ich rozpoznania będzie polski sąd powszechny właściwy miejscowo dla siedziby Partnera.</li>
                                <li>Prawem właściwym dla wszelkich kwestii związanych z ważnością oraz stosowaniem umowy oraz Regulaminu jest prawo polskie.</li>
                                <li>Szczegółowe informacje na temat przetwarzania danych osobowych pozyskiwanych za pośrednictwem strony dostępnej pod adresem https://changeshapers.pl znajdują się w „Polityce prywatności” na stronie https://higher.pl/polityka-prywatnosci.</li>
                            </ol>
                        </div>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export default RegulaminPage;